/* Loading */
.water {
  margin: 100px auto 0 auto;
  align-self: center;
  display: flex;
  width:200px;
  height: 200px;
  background-color: skyblue;
  border-radius: 50%;
  position: relative;
  box-shadow: inset 0 0 30px 0 rgba(0,0,0,.5), 0 4px 10px 0 rgba(0,0,0,.5);
  overflow: hidden;
}
.water:before, .water:after {
  content:'';
  position: absolute;
  width:200px;
  height: 200px;
  top:-80px;
  background-color: #fff;
}
.water:before {
  border-radius: 45%;
  background:rgba(255,255,255,.7);
  animation:wave 5s linear infinite;
}
.water:after {
  border-radius: 35%;
  background:rgba(255,255,255,.3);
  animation:wave 5s linear infinite;
}
@keyframes wave {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
