.verifyLoading {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  .progress {
    font-size: 18px;
    font-weight: bold;
    height: 20px;
    background-color: transparent;
  }
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    animation-name: rotate;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    .underlay {
      stroke: hsla(0,0%,62%,.4);
      z-index: 1;
    }
    .overlay {
      stroke: #000;
      z-index: 2;
      transition: all .6s ease-in-out;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
