.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 100%;
  overflow: hidden;
  .main-box {
    background-color: var(--mainBoxBgColor);
    margin-top: 40px;
    margin-bottom: 40px;
    border: 1px solid var(--mainBorderColor);

    .side-box {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: center;
      background-color: var(--sideBoxColor);
      padding: 30px 0;
      .topArea {
        .logo {
          display: flex;
          justify-content: center;
        }
        .support {
          justify-content: center;
          display: flex;
          margin-top: 20px;
          img {
            width: 24px;
          }
        }
        .section-box {
          margin-top: 20px;
          padding: 15px 10px;
          border: 1px solid var(--sideBoxSectionBorderColor);
          background-color: var(--sideBoxSectionBgColor);
          font-weight: bold;
          @include border-radius(4px);
          color: var(--sectionBoxTitleColor);

          .title {
            font-size: 15px;
          }

          .text {
            margin-right: 5px;
            font-weight: normal;
          }
        }
        .timer {
          .text {
            color: var(--timerTextColor);
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
      .bottomArea {
        display: flex;
        justify-content: center;
        .lang {
          margin-top: 20px;
          width: 94%;
        }
      }
    }

    .content-box {
      min-height: 500px;

      .progresses {
        display: flex;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 30px;

        .line {
          height: 4px;
          flex: 1;
          background: var(--progressLineColor);
        }

        .pass {
          background-color: var(--progressLineActiveColor) !important;

          .title {
            color: var(--progressTitleActiveColor) !important;
          }
        }

        .active {
          .title {
            color: var(--progressTitleActiveColor) !important;
          }
        }

        .steps {
          display: flex;
          background-color: var(--progressLineColor);
          color: var(--progressCircletextColor);
          font-size: 14px;
          width: 30px;
          height: 30px;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          position: relative;

          .title {
            position: absolute;
            top: -30px;
            color: var(--progressLineTitleColor);
            text-align: center;
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }
      .pointer {
        cursor: pointer;
      }
      .copy {
        display: block;
        transform: translateY(-50%);
        cursor: pointer;
        border: none;
        background-color: transparent;
        overflow-wrap: anywhere;
        i {
          padding-inline-end: 5px;
        }
        &:before {
          content: '';
          display: none;
          position: absolute;
          z-index: 9998;
          top: 30px;
          left: 50px;
          width: 0;
          height: 0;

          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid rgba(0,0,0,.82);
        }

        &:after {
          content: 'Copy to Clipboard';
          display: none;
          position: absolute;
          z-index: 9999;
          top: 34px;
          left: 0;
          width: 114px;
          height: 36px;
          color: #fff;
          font-size: 10px;
          line-height: 36px;
          text-align: center;

          background: rgba(0,0,0,.82);
          border-radius: 3px;
        }

        &:hover {
          background-color: #eee;

          &:before, &:after {
            display: block;
          }
        }

        &:active, &:focus {
          outline: none;

          &:after {
            content: 'Copied!';
          }
        }
      }
      .paymentMethodContent {
        margin: 0;
        text-align: justify;
        .paymentMethods2ndTpl {
          display: flex;
          flex-wrap: wrap;
          margin: 26px 0 0 0;
          padding: 0;
          list-style-type: none;
          li {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin: 0 15px 40px 15px;
            cursor: pointer;
            max-width: 120px;
            .holderImage {
              background-color: #f5f5f5;
              margin-bottom: 5px;
              img {
                padding: 10px;
                width: 100px;
                height: 100px;
                margin: 0 10px;
              }
            }
            .holderCaption {
              background-color: #f5f5f5;
              padding: 2px 6px;
              .caption {
                font-weight: bold;
                font-size: 13px;
              }
            }
            .buyURL {
              margin-top: 1px;
              background-color: #05e27e;
              color: #0D2426;
              padding: 3px 3px;
              font-weight: bold;
              font-size: 12px;
              border: 1px solid #b3b3b3;
            }
          }
        }
        .currency {
          font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
          font-weight: bold;
          cursor: pointer;
        }
        .paymentMethods {
          margin: 26px 0 0 0;
          padding: 0;
          list-style-type: none;
          li {
            display: flex;
            margin: 0 0 40px 0;
            cursor: pointer;
            img {
              width: 70px;
              height: 50px;
              margin-left: 10px;
            }
            .text {
              direction: rtl;
              text-align: justify;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .title {
                font-weight: bold;
                i {
                  color: #4cc111;
                }
              }
              .description {

              }
            }
          }
        }
      }
      .C2CContent {

        .description {
          font-weight: bold;
          margin-bottom: 40px;
        }
        ul.information {
          margin: 0;
          padding: 0;
          list-style-type: none;
          li {
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
            .key {
              font-weight: bold;
              margin-left: 10px;
              &.ltr {
                direction: ltr;
              }
            }
            .value {

            }
          }
        }
        .note {
          text-align: justify;
          margin-top: 40px;
          margin-bottom: 20px;
          //color: red;
          i {
            font-size: 15px;
            color: #c82424;
          }
        }
      }
      .wait {
        margin-top: 30px;
      }
    }
  }

  .ctc-gateway {
    margin: 30px 10px;
  }
}
