.internet-bank {
  .bank-description {
    margin-top: 20px;
    padding: 15px 10px;
    border: 1px solid var(--amountBoxBorderColor);
    background-color: var(--amountBoxBgColor);
    font-weight: bold;
    @include border-radius(4px);
    color: var(--sectionBoxTitleColor);
    align-items: center;
  }
}