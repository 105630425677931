.transfer-section {

  .otp-div {
    .tooltip-text {

      margin-top: -35px;
      width: 159px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      top: -10px;
      left: -10px;
      position: absolute;
      z-index: 1;
      font-size: 12px;

      &::after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }
    }

    @include media-query-max-width(384px) {
        button {
          font-size: 13px;
        }
    }

    @include media-query-in-between(340px ,367px) {
        button {
          font-size: 11px;
        }
    }

    @include media-query-max-width(340px) {
      button {
        font-size: 9px;
      }
    }
  }

  .details {
    padding: 0 10px;
    .logo {
      img {
        width: 150px;
      }
    }

    .amount {
      margin-top: 20px;
      padding: 15px 10px;
      border: 1px solid var(--amountBoxBorderColor);
      background-color: var(--amountBoxBgColor);
      font-weight: bold;
      @include border-radius(4px);
      color: var(--sectionBoxTitleColor);
      align-items: center;

      .title {
        font-size: 18px;
        margin-left: 10px;
      }

      .value {
        margin-right: 5px;
        margin-left: 5px;
        font-size: 16px;
        font-weight: bold;
      }
    }

    ul.description {
      padding-right: 0;
      margin-top: 20px;
      margin-bottom: 30px;
      text-align: justify;
    }
  }
}