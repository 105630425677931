@font-face {
    font-family: "IranSans";
    font-weight: 300;
    font-style: normal;
    src: url("../../fonts/iransans/IRANSans_Light.eot");
    src: url("../../fonts/iransans/IRANSans_Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/iransans/IRANSans_Light.woff2") format("woff2"), url("../../fonts/iransans/IRANSans_Light.woff") format("woff"), url("../../fonts/iransans/IRANSans_Light.ttf") format("truetype");
}

html[lang="fa"] {
    body {
        font-family: "IranSans", Tahoma, serif;
    }
}
html[lang="en"], html[lang="tr"], html[lang="az"], html[lang="ru"] {
    body {
        font-family: sans-serif, Tahoma;
    }
}

body {
    background-color: var(--bgColor) !important;
}
a {
    text-decoration: none !important;
}

.ltr {
    direction: ltr;
}

ul, ol {
    list-style-type: none;
    padding-right: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

input::placeholder
{
    text-align: right !important;      /* for Chrome, Firefox, Opera */
}
:-ms-input-placeholder
{
    text-align: right !important;      /* for IE 10-11 */
}
::-webkit-input-placeholder
{
    text-align: right !important;      /* for IE Edge */
}
