:root {
  --bgColor: #2193b0;
  --mainBoxBgColor: #fff;
  --mainBorderColor: #eaeaea;
  --sideBoxColor: #f7f7f7;
  --sideBoxSectionBorderColor: #cccccc;
  --sideBoxSectionBgColor: #fff;
  --sectionBoxTitleColor: #545454;
  --timerTextColor: #ea0000;
  --amountBoxBgColor: #f7f7f7;
  --amountBoxBorderColor: #eaeaea;
  --progressLineColor: silver;
  --progressLineActiveColor: #63d19e;
  --progressCircletextColor: #fff;
  --progressLineTitleColor: silver;
  --progressLineTitleActiveColor: silver;
  --progressTitleActiveColor: #000;
  --succressResultColor: #63d19e;
  --pendingResultColor: #FFA12BFF;
}

//.theme-dark {
//  --background-color: black;
//  --text-color: white;
//}
