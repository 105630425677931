// mixins
@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin border-radius-complete($topLeft, $topRight, $bottomRight, $bottomLeft) {
  @if $topLeft != null and $topLeft >= 0 {
    border-top-left-radius: $topLeft;
    -webkit-border-top-left-radius: $topLeft;
    -moz-border-radius-topleft: $topLeft;
  }

  @if $topRight != null and $topRight >= 0 {
    border-top-right-radius: $topRight;
    -webkit-border-top-right-radius: $topRight;
    -moz-border-radius-topright: $topRight;
  }

  @if $bottomLeft != null and $bottomLeft >= 0 {
    border-bottom-left-radius: $bottomLeft;
    -webkit-border-bottom-left-radius: $bottomLeft;
    -moz-border-radius-bottomleft: $bottomLeft;
  }

  @if $bottomRight != null and $bottomRight >= 0 {
    border-bottom-right-radius: $bottomRight;
    -webkit-border-bottom-right-radius: $bottomRight;
    -moz-border-radius-bottomright: $bottomRight;
  }
}

@mixin gradient($color1, $color2) {
  background-color: $color1;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$color1}, endColorstr=#{$color2});
  background-image: -moz-linear-gradient(center top, $color1, $color2);
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($color1), to($color2));
}

@mixin box-shadow($left, $top, $radius, $color) {
  box-shadow: $left $top $radius $color;
  -webkit-box-shadow: $left $top $radius $color;
  -moz-box-shadow: $left $top $radius $color;
}


@mixin transition($property, $duration, $easing: linear) {
  transition: $property $duration $easing;
  -webkit-transition: $property $duration $easing;
  -moz-transition: $property $duration $easing;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

@mixin backgroundSize($type) {
  -webkit-background-size: $type;
  background-size: $type;
}

// Prepare your "function"
@mixin media-query-in-between ($widthSmall, $widthMax) {
  @media (min-width:$widthSmall) and (max-width:$widthMax) {
    @content;
  }
}

@mixin media-query-max-width ($maxWidth) {
  @media only screen and (max-width: $maxWidth) {
    @content;
  }
}