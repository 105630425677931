.result-section {
  i {
    font-size: 80px;

  }
  .success-result {
    i {
      color: var(--succressResultColor);
    }
  }
  .canceled-result {
    i {
      color: var(--timerTextColor);
    }
  }
  .waitAccept-result {
    i {
      color: var(--pendingResultColor);
    }
  }

}